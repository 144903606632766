
import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";
import OtpInput from "./../OtpInput";

@Component({
	components: {
		OtpInput: OtpInput as any,
	},
})
export default class ResetPinForm extends BaseForm {
	@Prop({ default: {} })
	public metadata!: Record<string, any>;

	public labels: Record<string, string> = {
		pin: "New PIN",
	};

	public model: Record<string, any> = {
		pin: null,
	};

	public async send() {
		const response = await this.$api.post(this.action, { ...this.metadata, pin: this.model.pin });
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$emit("done", response.body);
	}
}
